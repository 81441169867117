/* eslint-disable */
import React, {useEffect, useState} from "react";
import { graphql, Link, navigate } from "gatsby";
import ReactPaginate from "react-paginate";
import parse from 'html-react-parser'
import themeVars from '../gatsby-plugin-chakra-ui/theme-vars'
import {
  Stack,
  Box,
  Flex,
  Heading,
  Button,
  Container
} from "@chakra-ui/react";
import Layout from "../components/Layout";
import { normalizePath } from "../utils/get-url-path";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import pageTheme from "../gatsby-plugin-chakra-ui/theme";
import SEO from "../components/SEO";
import { Breadcrumb } from "gatsby-plugin-breadcrumb";

const theme = extendTheme({ ...pageTheme });

export default ({ data, pageContext }) => {
  const [pagedEvents, setPagedEvents] = useState([])
  const [currDate] = useState(new Date().setHours(0,0,0,0))

  // Get current paginated events
  useEffect(() => {
    const { perEventPage, offset } = pageContext
    const sorted = data.allWpEvent.nodes.sort((a, b) => {
      const AD = new Date(a.eventsCPT.dateAndTime.date).setHours(0,0,0,0)
      const BD = new Date(b.eventsCPT.dateAndTime.date).setHours(0,0,0,0)
      return BD - AD
    })
    const sliced = sorted.slice(offset, offset + perEventPage)
    setPagedEvents(sliced) 
  }, [data.allWpEvent.nodes])

  // Breadcrumbs
  const {
    breadcrumb: { crumbs },
  } = pageContext;
  const crumbsUnslugged = crumbs.map((crumb) => {
    // Unsluggify breadcrumbs
    return {
      pathname: crumb.pathname,
      crumbLabel: crumb.crumbLabel
        .replace(/\-/g, " ")
        .replace(/\w\S*/g, (txt) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }),
    };
  });
  // Hero
  const { mtaEventSettings } = data.wp;
  const heroContent = {
    // Changing repeater field key back to what header needs
    heroSlides: mtaEventSettings.eventHeroContent.eventHeroSlides,
  };

  return (
    <ChakraProvider theme={theme}>
      <SEO title="Events" />
      <Layout heroContent={heroContent}>
        <Container maxW="1024px" centerContent mb="200px" mt="200px">
          {crumbs.length > 1 && (
            <Box textAlign="left" width="100%" mb="100px">
              <Breadcrumb crumbs={crumbsUnslugged} crumbSeparator=" » " />
            </Box>
          )}
          <Box mb="100px" maxWidth="700px" textAlign="center">
            We take pride in our reputation and the products and services we deliver. MTA is constantly involved in the community and interacting with our customers.
            For more information on the events listed, please contact us at <a href="tel:18004783211">1-800-478-3211.</a>
          </Box>
          <Stack spacing={25}>
            {pagedEvents.map((page, i) => {

              // Get event start date object
              const eventDate = new Date(page?.eventsCPT?.dateAndTime?.date).setHours(0,0,0,0)
              
              return (
              <Flex
                key={`event-${i}`}
                boxShadow="lg"
                color="white"
                justify="center"
                alignItems="stretch"
                textAlign="left"
                bg="transparent"
                mb={50}
                width="100%"
                flexDirection={['column', 'column', 'row']}
                borderRadius={themeVars.borderRadius}
                overflow="hidden"
                opacity={eventDate < currDate ? '80%' : ''}
              >
                <Box
                  color="rgb(35, 81, 138);"
                  flex="2"
                  padding={['20px', null, '50px']}
                >
                  <Box as="p" fontSize="3xl" mb="0">
                    {page.title}
                  </Box>
                  <Box as="p" fontSize="xs">
                    {!!page?.eventsCPT?.venue?.venueName && page.eventsCPT.venue.venueName !== '' ? page.eventsCPT.venue.venueName : 'Virtual Event'}
                    {!!page?.eventsCPT?.venue?.venueAddress?.streetNumber ? ' | ' + page.eventsCPT.venue.venueAddress.streetNumber + ' ' : ''}
                    {!!page?.eventsCPT?.venue?.venueAddress?.streetName ? page.eventsCPT.venue.venueAddress.streetName + ', ' : ''}
                    {!!page?.eventsCPT?.venue?.venueAddress?.city ? page.eventsCPT.venue.venueAddress.city : ''}
                  </Box>
                  <Box
                    sx={{
                      '.gatsby-image-wrapper': {
                        width: '100%!important'
                      }
                    }}
                  >
                    {parse(!!page?.eventsCPT?.eventShortDescription ? page.eventsCPT.eventShortDescription : 'No description.')}
                  </Box>
                </Box>
                <Box
                  className="themeGradient"
                  flex="1"
                  padding="20px"
                >
                  <Flex
                    textAlign="center"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <Box>
                      {eventDate < currDate && <Box as="p" mb="10px!important">Past Event</Box>}
                      <Heading as="h4" mb="20px!important">{!!page?.eventsCPT?.dateAndTime?.date ? page.eventsCPT.dateAndTime.date : ''}</Heading>
                    </Box>
                    <Heading as="h6" mb="20px!important">
                      {!!page?.eventsCPT?.dateAndTime?.startTime ? page.eventsCPT.dateAndTime.startTime + ' - ' : ''}
                      {!!page?.eventsCPT?.dateAndTime?.endTime ? page.eventsCPT.dateAndTime.endTime : ''}
                    </Heading>
                    <Link
                      className="button"
                      mb="0"
                      style={{ background: "white", color: "#84396c" }}
                      to={`${normalizePath(page.uri)}#eventStart`}
                    >
                      Read More
                    </Link>
                  </Flex>
                </Box>
              </Flex>
            )})}
          </Stack>
        </Container>

        {pageContext && pageContext.totalPages > 1 && (
          <Box mt={10} className="container" display="flex" justifyContent="center">
            <ReactPaginate
              previousLabel={
                pageContext?.page !== 1 && <Button color="white">«</Button>
              }
              nextLabel={
                pageContext?.totalPages !== pageContext.page && (
                  <Button color="white">»</Button>
                )
              }
              onPageChange={({ selected }) => {
                const page = selected + 1;
                const path = page === 1 ? `/event/` : `/event/${page}/`;
                navigate(path);
              }}
              disableInitialCallback
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageContext.totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              initialPage={pageContext.page - 1}
            />
          </Box>
        )}

      </Layout>
    </ChakraProvider>
  );
};

export const query = graphql`
  fragment ThumbnailEvent on File {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, quality: 95, placeholder: NONE)
    }
  }
  query EventPage {
    allWpEvent(sort: { fields: date, order: DESC }) {
      nodes {
        uri
        title
        content
        featuredImage {
          node {
            localFile {
              ...Thumbnail
            }
          }
        }
        eventsCPT {
          dateAndTime {
            date
            endDate
            startTime
            endTime
            fieldGroupName
          }
          eventShortDescription
          venue {
            venueName
            hideMap
            venueAddress {
              streetAddress
              streetName
              streetNumber
              city
            }
          }
        }
      }
    }
    wp {
      mtaEventSettings {
        eventHeroContent {
          eventHeroSlides {
            heroSlideContentH1
            heroSlideContentH4
            heroSlideLink {
              url
              title
              target
            }
            heroSlideIllustrationSelect
            heroSlideEffect
            secondaryButton {
              target
              title
              url
            }
            heroSlideImage {
              altText
              localFile {
                url
                ext
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, quality: 85, placeholder: NONE)
                }
              }
            }
          }
        }
      }
    }
  }
`;
